import { memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EntitySchema, EProductTypeSchema, ProductSchema } from '@greenisland-store/authorization';
import { Box, CircularProgress } from '@mui/material';

import ProductSelector from './components/ProductSelector';
import { useAuth } from '../auth/AuthProvider';
import LayoutContainer from './LayoutContainer';
import { BaseRouting } from './RouteInterfaces';
import getRoutes from './routes';

const HighLevelRouteContainer = () => {
  const [routes, setRoutes] = useState<BaseRouting | null>(null);
  const { authData, isAuthenticated } = useAuth();
  const { startupAgent, account } = authData || {};
  const { selectedEntity, selectedProduct } = account || {};
  const { pathname } = useLocation();
  const entities = useMemo(() => startupAgent?.entities ?? [], [startupAgent]);

  useEffect(() => window.scrollTo(0, 0), [pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      let productType;
      if (entities && selectedEntity && selectedProduct) {
        const currentEntity = entities.find((entity: EntitySchema) => entity.entityName === selectedEntity);

        if (currentEntity) {
          const currentProduct = currentEntity.products.find(
            (product: ProductSchema) => product.productName === selectedProduct.productName
          );

          if (currentProduct) {
            productType = currentProduct.productType as EProductTypeSchema;
          }
        }
      }
      const initRoutes = getRoutes(productType);
      setRoutes(initRoutes);
    } else {
      setRoutes(getRoutes());
    }
  }, [isAuthenticated, entities, selectedEntity, selectedProduct]);

  if (isAuthenticated && !selectedEntity && !selectedProduct) {
    return <ProductSelector />;
  }

  if (!routes) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return <LayoutContainer agentEntities={entities} entitySelected={account} {...routes} />;
};

export default memo(HighLevelRouteContainer);
