import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { ELanguagesSchema, ServiceContractSchema, TranslationsSchema, useGetServiceContracts } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import ServiceContractsContentDialog from './ServiceContractsContentDialog';
import ServiceContractsUpdateDialog from './ServiceContractsUpdateDialog';
import ServiceContractsViewDialog from './ServiceContractsViewDialog';

const ServiceContractsTable = () => {
  const { t } = useTranslation();
  const [selectedContent, setSelectedContent] = useState<ServiceContractSchema | undefined>(undefined);
  const [selectedViewContract, setSelectedViewContract] = useState<ServiceContractSchema | undefined>(undefined);
  const [selectedUpdateContract, setSelectedUpdateContract] = useState<ServiceContractSchema | undefined>(undefined);
  const canReadServiceContracts = usePermission(OnlineCasinoPermissions.getServiceContracts);
  const canReadServiceContract = usePermission(OnlineCasinoPermissions.getServiceContract);
  const canUpdateServiceContract = usePermission(OnlineCasinoPermissions.addVersionToServiceContract);

  const { data, isLoading, isError } = useGetServiceContracts({ query: { enabled: canReadServiceContracts } });

  const getLanguageTranslationContentHandler = useCallback(
    (translations: TranslationsSchema, language: ELanguagesSchema) => {
      const content = translations?.find(translation => translation?.language === language)?.content;

      return <Typography variant="body2">{content || '-'}</Typography>;
    },
    []
  );

  const columns = useMemo<GridColumns<ServiceContractSchema>>(
    () => [
      {
        field: 'name',
        headerName: t('serviceContracts.table.header.nameEN'),
        sortable: false,
        flex: 3,
        renderCell: ({ row }) => getLanguageTranslationContentHandler(row.name, ELanguagesSchema.EN),
      },
      {
        field: 'contractType',
        headerName: t('serviceContracts.history.table.header.contractType'),
        sortable: false,
        flex: 2,
      },
      {
        field: 'required',
        headerName: t('serviceContracts.table.header.required'),
        sortable: false,
        flex: 1,
        renderCell: params => (params.value ? t('yes') : t('no')),
      },
      {
        field: 'latestVersion',
        headerName: t('serviceContracts.table.header.latestVersion'),
        sortable: false,
        flex: 1.5,
        renderCell: ({ row }) =>
          `${row.latestVersion.version.majorVersion}.${row.latestVersion.version.minorVersion}.${row.latestVersion.version.patchVersion}`,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        flex: 3,
        getActions: (params: GridRowParams<ServiceContractSchema>) => [
          <>
            <Button
              key="contentButton"
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedContent(params.row);
              }}
            >
              {t('serviceContracts.table.viewContent')}
            </Button>
            {canReadServiceContract && (
              <Tooltip title={t('view')} key="viewTooltip">
                <>
                  <GridActionsCellItem
                    title={t('view')}
                    label={t('view')}
                    icon={<FontAwesomeIcon icon={faEye} />}
                    key="view"
                    onClick={() => {
                      setSelectedViewContract(params.row);
                    }}
                  />
                </>
              </Tooltip>
            )}
            {canUpdateServiceContract && (
              <Tooltip title={t('edit')} key="editTooltip">
                <>
                  <GridActionsCellItem
                    title={t('edit')}
                    label={t('edit')}
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    key="edit"
                    onClick={() => {
                      setSelectedUpdateContract(params.row);
                    }}
                  />
                </>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [t, getLanguageTranslationContentHandler, canReadServiceContract, canUpdateServiceContract]
  );

  const rows = useMemo(
    () => data?.serviceContracts.map(contract => ({ ...contract, id: contract.serviceContractId })) ?? [],
    [data]
  );

  return (
    <PermissionWrapper
      errorMessage={t('serviceContracts.errors.fetchAllError')}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.getServiceContracts}
    >
      <>
        <DataGridContainer>
          <LimitedDataGrid density="compact" autoHeight loading={isLoading} rows={rows} columns={columns} />
        </DataGridContainer>

        {selectedContent && (
          <ServiceContractsContentDialog
            serviceContract={selectedContent}
            open={!!selectedContent}
            onClose={() => setSelectedContent(undefined)}
          />
        )}
        {selectedViewContract && (
          <ServiceContractsViewDialog
            serviceContractId={selectedViewContract.serviceContractId}
            open={!!selectedViewContract}
            onClose={() => setSelectedViewContract(undefined)}
          />
        )}
        {selectedUpdateContract && (
          <ServiceContractsUpdateDialog
            required={selectedUpdateContract.latestVersion.requiredToSign}
            contractContent={selectedUpdateContract.latestVersion.content}
            contractId={selectedUpdateContract.serviceContractId}
            open={!!selectedUpdateContract}
            onClose={() => setSelectedUpdateContract(undefined)}
          />
        )}
      </>
    </PermissionWrapper>
  );
};

export default ServiceContractsTable;
