import { memo, useCallback, useMemo } from 'react';
import { BaseProductSchema, EProductTypeSchema } from '@greenisland-store/authorization';
import { Box, Card, Typography } from '@mui/material';

import { useAuth } from '../../auth/AuthProvider';

enum ProductCategory {
  PROVIDER = 'ProSvc',
  PAYMENT = 'Payments',
  RED_PANDA = 'RedPanda',
}

const ProductSelector = () => {
  const { authData, setProduct } = useAuth();
  const { startupAgent: startupData } = authData || {};

  const entities = useMemo(() => startupData?.entities ?? [], [startupData]);

  const onProductSelect = useCallback(
    (name: string) => {
      const selectedEntity = entities?.find(({ products }) =>
        products?.find(({ productName }) => productName === name)
      );
      if (selectedEntity) {
        const product = selectedEntity.products
          .sort((a, b) => {
            if (
              a?.productType === EProductTypeSchema.OnlineCasino &&
              b?.productType !== EProductTypeSchema.OnlineCasino
            )
              return -1;
            if (
              a?.productType !== EProductTypeSchema.OnlineCasino &&
              b?.productType === EProductTypeSchema.OnlineCasino
            )
              return 1;
            return a?.productName?.localeCompare(b?.productName);
          })
          .find(({ productName }) => productName === name);
        if (product) {
          setProduct(selectedEntity.entityName, product);
        }
      }
    },
    [entities, setProduct]
  );

  const tenants = useMemo(() => {
    const accumulator = new Map<string, Array<BaseProductSchema>>();

    entities
      ?.flatMap(entity => entity?.products)
      .forEach(product => {
        const tenantName = product?.productName
          ?.replace(ProductCategory.PROVIDER, '')
          ?.replace(ProductCategory.PAYMENT, '')
          ?.replace(ProductCategory.RED_PANDA, '');
        if (accumulator.has(tenantName)) accumulator.get(tenantName)?.push(product);
        else accumulator.set(tenantName, [product]);
      });

    const sortedTenants = Array.from(accumulator)?.sort(([, productsA], [, productsB]) => {
      return productsA[0]?.productName?.localeCompare(productsB[0]?.productName);
    });

    return sortedTenants?.map(([, products]) =>
      products?.sort((a, b) => {
        if (a?.productType === EProductTypeSchema.OnlineCasino && b?.productType !== EProductTypeSchema.OnlineCasino)
          return -1;
        if (a?.productType !== EProductTypeSchema.OnlineCasino && b?.productType === EProductTypeSchema.OnlineCasino)
          return 1;
        return a?.productName?.localeCompare(b?.productName);
      })
    );
  }, [entities]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      height="100%"
      padding={4}
      gap={4}
    >
      <div>
        <Typography variant="h4" gutterBottom textAlign="center">
          Welcome back, {startupData?.name}.
        </Typography>
        <Typography variant="subtitle1" gutterBottom textAlign="center">
          You&apos;re logged in to the following accounts:
        </Typography>
      </div>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          },
          gap: 2,
        }}
      >
        {tenants?.map(([{ productId, productName, logoUrl }]) => (
          <Card
            key={productId}
            id={`${productId}-${productName}`}
            sx={{ display: 'flex', alignItems: 'center', padding: 2, cursor: 'pointer', userSelect: 'none' }}
            onClick={() => onProductSelect(productName)}
          >
            <Box component="img" src={logoUrl} sx={{ width: 56, height: 20, marginRight: 2, objectFit: 'contain' }} />
            <Typography variant="h6" sx={{ fontSize: 16 }}>
              {productName}
            </Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default memo(ProductSelector);
