import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountingInvoiceV2 } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { BaseProductSchema } from '@greenisland-store/authorization';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, styled } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { useAuth } from '../../../../../../auth/AuthProvider';

const StyledPrintableWrapper = styled(Box)(() => ({
  ['@media print']: {
    height: '100%',
    visibility: 'visible',
    marginTop: '-3.5rem',
    '& *': {
      visibility: 'visible !important',
    },
    '& .MuiCardHeader-root': {
      display: 'none',
    },
  },
}));

const StyledContainer = styled(Box)<{ addPageBreak?: boolean }>(({ addPageBreak }) => ({
  display: 'flex',
  flexDirection: 'column',
  pageBreakAfter: 'always',
  ['@media print']: {
    pageBreakAfter: 'always',
  },
  ...(addPageBreak && {
    pageBreak: {
      pageBreakBefore: 'always',
      breakBefore: 'always',
      ['@media print']: {
        breakBefore: 'always',
        pageBreakBefore: 'always',
      },
    },
  }),
}));

const StyledHeader = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '2rem',
  flexDirection: 'row-reverse',
}));

const StyledHead = styled('head')(() => ({
  marginTop: '4rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
}));

const StyledLogo = styled('img')(() => ({
  visibility: 'hidden',
  height: '150px',
}));

const StyledDate = styled('span')(() => ({
  fontSize: '0.9rem',
  marginBottom: '1.5rem',
}));

const StyledTitle = styled('h1')(() => ({
  fontSize: '1.4rem',
  marginBottom: '1.5rem',
  marginTop: 0,
  textTransform: 'capitalize',
}));

const StyledTable = styled('table')(() => ({
  borderCollapse: 'collapse',
  marginBottom: '1.5rem',
  '& th': {
    textTransform: 'capitalize',
    textAlign: 'left',
  },
}));

const StyledDivider = styled('tr')(() => ({
  borderBottom: '1px solid rgba(0, 0, 0, 0.5)',
  margin: '5px 0px',
  '& tr': {
    paddingBottom: 5,
    boxSizing: 'border-box',
    width: '50%',
  },
  '& td': {
    padding: '10px 0px',
    boxSizing: 'border-box',
    width: '50%',
  },
}));

const StyledTableRowTotal = styled('tr')<{ addSubTotal?: boolean }>(({ addSubTotal }) => ({
  fontWeight: 600,
  '& td': {
    paddingTop: '1rem',
    width: '50%',
    ...(addSubTotal && {
      paddingTop: 10,
      boxSizing: 'border-box',
      width: '50%',
    }),
  },
}));

const StyledPrintButton = styled(Button)(() => ({
  marginBottom: 15,
  marginLeft: 'auto',
}));

const StyledCapitalize = styled('span')(() => ({
  textTransform: 'capitalize',
}));

const StyledUppercase = styled('span')(() => ({
  textTransform: 'uppercase',
}));

const StyledPlatformComissionTableHeader = styled('th')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    minWidth: '110px',
  },
  [theme.breakpoints.up('md')]: {
    minWidth: '200px',
  },
}));

const AccountingInvoiceTemplate = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date') || format(new Date(), 'yyyy-MM-dd');
  const canReadAccountingInvoice = usePermission(OnlineCasinoPermissions.getAccountingInvoiceV2);
  const { authData } = useAuth();
  const { account, startupAgent } = authData || {};
  const { selectedEntity, selectedProduct } = account || {};
  const { data, isLoading } = useGetAccountingInvoiceV2({ date }, { query: { enabled: canReadAccountingInvoice } });

  let fullProduct: BaseProductSchema | undefined;
  if (startupAgent && startupAgent.entities) {
    const fullEntity = startupAgent.entities.find(entity => entity.entityName === selectedEntity);
    if (fullEntity) {
      fullProduct = fullEntity.products.find(product => product.productName === selectedProduct?.productName);
    }
  }

  const startDate = format(fromUnixTime(data?.periodStart ?? 0), 'dd/MM/yyyy');
  const endDate = format(fromUnixTime(data?.periodEnd ?? 0), 'dd/MM/yyyy');
  const invoiceDate = format(fromUnixTime(data?.invoiceDate ?? 0), 'dd/MM/yyyy');

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (!data) return <Box>{t('noData')}</Box>;

  return (
    <Card>
      <CardHeader
        action={
          <StyledPrintButton variant="contained" startIcon={<FontAwesomeIcon icon={faPrint} />} onClick={window.print}>
            {t('printDocument')}
          </StyledPrintButton>
        }
      />
      <CardContent>
        <StyledPrintableWrapper>
          <StyledContainer>
            <StyledHeader>
              <StyledCapitalize>
                {fullProduct?.invoiceName}
                <br />
                {fullProduct?.street} {fullProduct?.houseNumber}
                <br />
                {fullProduct?.postalCode} {fullProduct?.city}
                <br />
                <StyledUppercase>{fullProduct?.vatNumber}</StyledUppercase>
              </StyledCapitalize>
              <StyledLogo src="/img/gi_logo_cropped.png" alt="Green Island Logo" />
            </StyledHeader>
            <StyledDate>
              {t('date')}: {invoiceDate}
            </StyledDate>
            <StyledTitle>
              {`${t('revenue')}-${format(fromUnixTime(data.periodStart), 'yyyyMMdd')}-${format(
                fromUnixTime(data.periodEnd),
                'yyyyMMdd'
              )}`}
            </StyledTitle>
            <StyledDate>{`${t('from')} ${startDate} ${t('till')} ${endDate}`}</StyledDate>
            <StyledTable>
              <thead>
                <StyledDivider>
                  <th>{t('provider')}</th>
                  <th>{t('margin')}</th>
                </StyledDivider>
              </thead>
              <tbody>
                {data.operatorDetails.providerSummaries.map((item, index) => {
                  return (
                    <StyledDivider key={`od-providerSummaries-${index}`}>
                      <td>{item.provider}</td>
                      <td>{formatCurrency(item.margin)}</td>
                    </StyledDivider>
                  );
                })}
                <StyledTableRowTotal addSubTotal={true}>
                  <td>{t('subtotal')}</td>
                  <td>{formatCurrency(data.operatorDetails.providerSubtotal)}</td>
                </StyledTableRowTotal>
              </tbody>
            </StyledTable>
            <StyledTable>
              <thead>
                <StyledDivider>
                  <th>{t('platform')}</th>
                  <th>{t('margin')}</th>
                </StyledDivider>
              </thead>
              <tbody>
                {data.operatorDetails.platformSummaries.map((item, index) => (
                  <StyledDivider key={`od-platformSummaries-${index}`}>
                    <td>{item.platform}</td>
                    <td>{formatCurrency(item.margin)}</td>
                  </StyledDivider>
                ))}
                <StyledTableRowTotal addSubTotal={true}>
                  <td>{t('subtotal')}</td>
                  <td>{formatCurrency(data.operatorDetails.platformSubtotal)}</td>
                </StyledTableRowTotal>
                <StyledTableRowTotal>
                  <td>{t('total')}</td>
                  <td>{formatCurrency(data.operatorDetails.operatorTotal)}</td>
                </StyledTableRowTotal>
              </tbody>
            </StyledTable>
          </StyledContainer>
          <StyledContainer addPageBreak={true}>
            <StyledHead>
              <span>
                GREEN ISLAND NV
                <br />
                Treft 33 B.0002
                <br />
                1853 Grimbergen
                <br />
                BE 0422.851.308
              </span>
              <StyledCapitalize>
                {fullProduct?.invoiceName}
                <br />
                {fullProduct?.street} {fullProduct?.houseNumber}
                <br />
                {fullProduct?.postalCode} {fullProduct?.city}
                <br />
                {fullProduct?.vatNumber}
              </StyledCapitalize>
            </StyledHead>
            <StyledDate> {`${t('date')}: ${invoiceDate}`}</StyledDate>
            <StyledTitle>
              {`${t('revenueShareGreenIsland')}-${format(fromUnixTime(data.periodStart), 'yyyyMMdd')}-${format(
                fromUnixTime(data.periodEnd),
                'yyyyMMdd'
              )}`}
            </StyledTitle>
            <StyledDate>{`${t('from')} ${startDate} ${t('till')} ${endDate}`}</StyledDate>
            <StyledTable>
              <thead>
                <StyledDivider>
                  <th>{t('provider')}</th>
                  <th>{t('margin')}</th>
                  <StyledPlatformComissionTableHeader>
                    {t('common.platformCommision')}
                  </StyledPlatformComissionTableHeader>
                </StyledDivider>
              </thead>
              <tbody>
                {data.providerDetails.providerSummaries.map((item, index) => (
                  <StyledDivider key={`pd-providerSummaries-${index}`}>
                    <td>{item.provider}</td>
                    <td>{formatCurrency(item.margin)}</td>
                    <td>{formatCurrency(item.platformCommision)}</td>
                  </StyledDivider>
                ))}
                <StyledTableRowTotal addSubTotal={true}>
                  <td>{t('subtotal')}</td>
                  <td>{formatCurrency(data.providerDetails.providerSubtotal)}</td>
                  <td>{formatCurrency(data.providerDetails.providerTotal.platformCommisionTotal)}</td>
                </StyledTableRowTotal>
              </tbody>
            </StyledTable>
            <StyledTable>
              <thead>
                <StyledDivider>
                  <th>{t('platform')}</th>
                  <th>{t('margin')}</th>
                  <StyledPlatformComissionTableHeader></StyledPlatformComissionTableHeader>
                </StyledDivider>
              </thead>
              <tbody>
                {data.providerDetails.platformSummaries.map((item, index) => (
                  <StyledDivider key={`pd-platformSummaries-${index}`}>
                    <td>{item.platform}</td>
                    <td>{formatCurrency(item.margin)}</td>
                    <td></td>
                  </StyledDivider>
                ))}
                <StyledTableRowTotal addSubTotal={true}>
                  <td>{t('subtotal')}</td>
                  <td>{formatCurrency(data.providerDetails.platformSubtotal)}</td>
                  <td></td>
                </StyledTableRowTotal>
                <StyledTableRowTotal>
                  <td>{t('total')}</td>
                  <td>{formatCurrency(data.providerDetails.providerTotal.total)}</td>
                  <td>{formatCurrency(data.providerDetails.providerTotal.platformCommisionTotal)}</td>
                </StyledTableRowTotal>
              </tbody>
            </StyledTable>
          </StyledContainer>
        </StyledPrintableWrapper>
      </CardContent>
    </Card>
  );
};

export default AccountingInvoiceTemplate;
