import { useAuthenticator } from '@aws-amplify/ui-react';
import ky, { Options } from 'ky';
import { useMemo } from 'react';
import { IEntityState } from '../auth/types';

import { useApi } from './apiContext';
import { StartupAgentSchema } from './authorization';

const BASE_URL = 'https://boa.dev.greenisland.be';
interface RequestConfig {
  method: 'get' | 'put' | 'patch' | 'post' | 'delete';
  url: string;
  params?: any;
  data?: any;
  responseType?: string;
  signal?: AbortSignal;
  headers?: HeadersInit;
}

const intercept = (request: Request, options: Options, response: Response) => {
  if (response.status === 401) {
    // TODO: Enable when SetAlertAction is available in @greenisland/stores
    // store.dispatch(setErrorAlert('unauthorized'));
  }
  if (response.status < 200 || response.status >= 300) {
    return Promise.reject(response);
  }
};

const api = ky.extend({ prefixUrl: BASE_URL, hooks: { afterResponse: [intercept] }, timeout: 120000 });

export const useCustomInstance = <T>(): ((config: RequestConfig) => Promise<T>) => {
  const baseUrl = useApi();
  const { user } = useAuthenticator(context => [context.user]);
  const token = useMemo(() => user?.getSignInUserSession()?.getIdToken()?.getJwtToken(), [user]);
  const currentStartupDataJson = localStorage.getItem('startupData');
  const startupAgent: StartupAgentSchema = currentStartupDataJson ? JSON.parse(currentStartupDataJson) : {};
  const currentAccountJson = localStorage.getItem('currentAccount');
  const { selectedEntity, selectedProduct }: IEntityState = currentAccountJson ? JSON.parse(currentAccountJson) : {};

  let productType: string | undefined = undefined;

  const entity =
    startupAgent && startupAgent?.entities
      ? startupAgent.entities.find(indEntity => {
          if (indEntity.entityName === selectedEntity) return indEntity;
        })
      : undefined;

  if (entity) {
    entity.products.find(product => {
      if (product.productName === selectedProduct?.productName) {
        productType = product.productType;
      }
    });
  }

  const endp =
    selectedEntity && selectedProduct?.productName
      ? productType
        ? `/${productType}/${selectedEntity}/${selectedProduct.productName}`
        : `/${selectedEntity}/${selectedProduct.productName}`
      : '';

  const prefixUrl = `${baseUrl}${endp}`;

  return (config: RequestConfig) => {
    const controller = new AbortController();
    const { signal } = controller;
    const promise = api(`${config.url.substring(1)}`, {
      method: config.method,
      json: config.data,
      searchParams: config.params,
      headers: {
        ...config.headers,
        authorization: `Bearer ${token}`,
      },
      prefixUrl,
      signal,
    });
    return promise.json<T>();
  };
};

export default useCustomInstance;
