import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CreateServiceContractSchema,
  ELanguagesSchema,
  EServiceContractTypeSchema,
  getGetServiceContractsQueryKey,
  TranslationsSchema,
  useCreateServiceContract,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import RichTextEditor from 'src/app/components/RichTextEditor';

import { SelectInput } from '@greenisland-common/components/atoms';
import Select from '@greenisland-common/components/atoms/Select';
import LanguageTranslationSelect from '@greenisland-common/components/organisms/LanguageTranslationSelect';

import { SERVICE_CONTRACTS_ERROR_REASONS } from './serviceContractConstants';
interface Props {
  open: boolean;
  onClose: () => void;
}

const ServiceContractsCreationDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [selectedLanguage, setSelectedLanguage] = useState<ELanguagesSchema>(ELanguagesSchema.EN);
  const [content, setContent] = useState<TranslationsSchema>([]);
  const placeholderText = `<p>${t('serviceContracts.create.placeholder')}</p>`;

  const methods = useForm<CreateServiceContractSchema>({
    defaultValues: { name: [{ language: 'EN', content: '' }] },
  });
  const { handleSubmit, control } = methods;

  const { mutate: createServiceContract, isLoading } = useCreateServiceContract({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetServiceContractsQueryKey());
        onClose();
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        const translationKey =
          error && 'type' in error && error.type && SERVICE_CONTRACTS_ERROR_REASONS[error.type]
            ? SERVICE_CONTRACTS_ERROR_REASONS[error.type]
            : 'somethingWentWrong';

        enqueueSnackbar(t(translationKey), { variant: 'error' });
      },
    },
  });

  const onEditorChange = (value: string) => {
    const existingIndex = content.findIndex(item => item.language === selectedLanguage);

    if (existingIndex !== -1) {
      // Replace the existing content for the language
      const updatedContent = [...content];
      updatedContent[existingIndex] = { language: selectedLanguage, content: value };
      setContent(updatedContent);
    } else {
      // Append new content if language wasn't added yet
      setContent([...content, { language: selectedLanguage, content: value }]);
    }
  };

  const onSubmit = (data: Omit<CreateServiceContractSchema, 'content'>) => {
    createServiceContract({ data: { ...data, content: content } });
  };

  const calculateInitialValue = () => {
    return content.find(translation => translation.language === selectedLanguage)?.content || placeholderText;
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <Box>
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle>{t('serviceContracts.create.title')}</DialogTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
              <DialogContent>
                <LanguageTranslationSelect name="name" mainTitle={t('serviceContracts.create.contractName')} />
                <FormControlLabel
                  label={'Required'}
                  sx={{ marginBottom: 2 }}
                  control={
                    <Controller
                      name={'required'}
                      control={control}
                      defaultValue={false}
                      render={({ value, onChange }) => (
                        <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
                      )}
                    />
                  }
                />
                <SelectInput
                  name={'contractType'}
                  label={t('serviceContracts.create.contractType')}
                  options={Object.keys(EServiceContractTypeSchema).map(key => ({ value: key, label: key }))}
                  defaultValue={EServiceContractTypeSchema.Other}
                  required={true}
                  size="small"
                  sx={{ marginBottom: 2, maxWidth: 300 }}
                />
                <Box>
                  <Typography fontWeight={500} variant="body1" fontSize="large" mb={1}>
                    {t('serviceContracts.create.contractContent')}
                  </Typography>
                  <Select
                    id="contentLanguage"
                    label={t('serviceContracts.content.contentLanguage')}
                    value={selectedLanguage}
                    sx={{ marginBottom: 1 }}
                    onChange={event => setSelectedLanguage(event.target.value as ELanguagesSchema)}
                    options={Object.values(ELanguagesSchema).map(language => language.toString())}
                  />
                  <RichTextEditor
                    key={`Editor-${selectedLanguage}`}
                    initialValue={calculateInitialValue()}
                    onEditorChange={onEditorChange}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button fullWidth={true} variant="text" onClick={onClose} color="secondary">
                  {t('cancel')}
                </Button>
                <LoadingButton
                  fullWidth={true}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {t('save')}
                </LoadingButton>
              </DialogActions>
            </form>
          </FormProvider>
        </Card>
      </Box>
    </Dialog>
  );
};

export default ServiceContractsCreationDialog;
