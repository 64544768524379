import React, { createContext, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { ProductSchema, useGetStartup } from '@greenisland-store/authorization';
import { useLocalStorage } from '@lilib/hooks';
import { Amplify, Auth } from 'aws-amplify';

import { AuthData, EIdentityProvider } from './types';

Amplify.configure(__AMPLIFY_CONFIG__);

interface AuthContextType {
  isAuthenticated: boolean;
  authData: AuthData | null;
  logout: () => Promise<void>;
  setProduct: (entity: string, product: ProductSchema) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <Authenticator.Provider>
      <AuthProviderInner>{children}</AuthProviderInner>
    </Authenticator.Provider>
  );
};

const AuthProviderInner = memo(({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user, authStatus } = useAuthenticator(context => [context.user, context.authStatus]);
  const token = useMemo(() => user?.getSignInUserSession()?.getIdToken()?.getJwtToken(), [user]);
  const [currentAccount, setCurrentAccount] = useLocalStorage<{
    selectedEntity: string;
    selectedProduct: ProductSchema;
  }>('currentAccount', {
    polling: true,
    pollingInterval: 100,
  });

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      navigate('/login');
    }
  }, [authStatus, navigate]);

  const logout = useCallback(async () => {
    // We clear these items because of an annoying bug (not 100% sure but it works so yeah)
    // https://github.com/aws-amplify/amplify-flutter/issues/401#issuecomment-1224270594
    localStorage.removeItem('amplify-signin-with-hostedUI');
    localStorage.removeItem('amplify-redirected-from-hosted-ui');

    await Auth.signOut();
    queryClient.clear();
    navigate('/login');
    localStorage.removeItem('currentAccount');
    localStorage.removeItem('startupData');
  }, [navigate, queryClient]);

  const { data: startupData } = useGetStartup({
    query: {
      enabled: !!user && authStatus === 'authenticated' && !!token,
      onError: logout,
      onSuccess(data) {
        localStorage.setItem('startupData', JSON.stringify(data));
      },
    },
  });

  const setProduct = useCallback(
    (entity: string, product: ProductSchema) => {
      setCurrentAccount(prev => ({ ...prev, selectedEntity: entity, selectedProduct: product }));
      queryClient.clear();
      navigate('/');
    },
    [setCurrentAccount, queryClient, navigate]
  );

  const authContextValue = useMemo(
    () => ({
      isAuthenticated: authStatus === 'authenticated' && !!user && !!startupData,
      authData: {
        cognitoUsername: user?.attributes?.email,
        startupAgent: startupData,
        identityProvider: EIdentityProvider.Cognito,
        account: currentAccount,
      },
      logout,
      setProduct,
    }),
    [authStatus, user, startupData, currentAccount, logout, setProduct]
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
