import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ApiContext } from '@greenisland-store/apiContext';
import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';

import { setupI18n } from './app/i18n';
import { GIGlobalCss } from './app/theme';
import { AuthProvider } from './auth/AuthProvider';
import HighLevelRouteContainer from './routes/HighLevelRouteContainer';
import config from './config';

LicenseInfo.setLicenseKey(
  '9cefa37ead05201a0212499b96b6dedcTz03MTYzMixFPTE3MjIxNTUzNzUwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false },
    mutations: { retry: false },
  },
});

function App() {
  const [configuredApi, setConfiguredApi] = useState(false);

  useEffect(() => {
    setupI18n();
    setConfiguredApi(true);
  }, []);

  if (!configuredApi) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ApiContext.Provider value={config.cognito.API_ENDPOINT}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <HighLevelRouteContainer />
          </AuthProvider>
          <GIGlobalCss />
          <CssBaseline />
        </BrowserRouter>
        {process.env.NODE_ENV !== 'production' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      </QueryClientProvider>
    </ApiContext.Provider>
  );
}

export default App;
