import { memo, useMemo } from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router';
import { StartupSettingsSchema } from '@greenisland-api';
import { hasFeatureFlags } from '@greenisland-core/featureFlags';
import { canAccess } from '@greenisland-core/permissions';
import { AgentDetailsSchema, EntitiesSchema, useGetCurrentAgent } from '@greenisland-store/authorization';
import { useTenantContext } from 'src/app/context/TenantContext';

import { IEntityState } from '../../auth/types';
import { BaseRoute, BaseRouting } from '../RouteInterfaces';
import BackofficeLayout from './BackofficeLayout';
import Oops from './Oops';

const getAccessibleRoutes = (
  routes: BaseRoute[],
  currentAgent: AgentDetailsSchema | undefined,
  tenant: StartupSettingsSchema
) => {
  const accessibleRoutes = routes
    .filter(
      ({ permissions, strictPermissions }) =>
        currentAgent && canAccess(currentAgent, permissions ?? [], Boolean(strictPermissions))
    )
    .filter(({ featureFlag }) => tenant && hasFeatureFlags(tenant, featureFlag ?? []))
    .map(baseRoute => {
      if (baseRoute.children) {
        baseRoute.children = getAccessibleRoutes(baseRoute.children, currentAgent, tenant);
      }
      return baseRoute;
    });
  return accessibleRoutes;
};

interface BackofficeRoutesWrapperProps extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

const BackofficeRoutesWrapper = ({
  routes,
  entitySelected,
  identifier,
  ...restProps
}: BackofficeRoutesWrapperProps) => {
  const { selectedEntity, selectedProduct } = entitySelected;
  const { data: currentAgent } = useGetCurrentAgent();
  const tenant = useTenantContext();

  const accessibleRoutes = useMemo(
    () => getAccessibleRoutes(routes, currentAgent, tenant),
    [currentAgent, routes, tenant]
  );

  const mapRoutesForUseElems = (routesForUse: Array<BaseRoute>, parentRoute: string): Array<RouteObject> => {
    const toReturn: Array<RouteObject> = routesForUse.map(
      ({ path, element, children, exact, index, redirect, pathTo }) => {
        const Comp: any = element;

        return {
          ...(children ? { children: mapRoutesForUseElems(children, `${parentRoute}/${path}`) } : null),
          element: redirect && pathTo ? <Navigate to={pathTo} replace={true} /> : <Comp />,
          caseSensitive: exact,
          path,
          index,
        };
      }
    );

    toReturn.push({ path: '*', element: <div>Not Found</div> });

    return toReturn;
  };
  const elements = useRoutes([
    {
      caseSensitive: true,
      path: '/',
      element: <Navigate to={`/${selectedEntity}/${selectedProduct.productName}/dashboard`} replace />,
    },
    {
      caseSensitive: true,
      path: `/${selectedEntity}/${selectedProduct.productName}`,
      element: <Navigate to={`/${selectedEntity}/${selectedProduct.productName}/dashboard`} replace />,
    },
    {
      caseSensitive: true,
      children: [...mapRoutesForUseElems(accessibleRoutes, `/${selectedEntity}/${selectedProduct.productName}`)],
      path: `${selectedEntity}/${selectedProduct.productName}`,
      element: (
        <BackofficeLayout
          {...restProps}
          identifier={identifier}
          routes={routes}
          entitySelected={entitySelected}
          canAccessRoutes={accessibleRoutes}
        />
      ),
    },
    { path: 'oops', element: <Oops /> },
  ]);

  return elements;
};

export default memo(BackofficeRoutesWrapper);
