import { createContext, memo, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { EWithdrawalTypeSchema } from '@greenisland-api';
import { Card, CardContent, Stack, Tabs } from '@mui/material';

import { useAuth } from '../../../../auth/AuthProvider';
import { WithdrawalsViews } from '../../../../routes/OnlineCasino/TaskRoutes';
import WithdrawalTab from './WithdrawalTab';

export const WithdrawalsContext = createContext<{ setIsBlocking: any; tableRef: any }>({
  setIsBlocking: null,
  tableRef: null,
});

const Withdrawals = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);
  const [value, setValue] = useState(0);
  const [isBlocking, setIsBlocking] = useState(false);
  const { authData } = useAuth();
  const { account } = authData || {};
  const { selectedEntity, selectedProduct } = account || {};

  useEffect(() => {
    if (WithdrawalsViews) {
      const split = pathname.replace(/\/$/, '').split('/');
      const lastEntryInUrl = split[split.length - 1];
      if (lastEntryInUrl.toLowerCase() === 'withdrawals') {
        setValue(0);
        navigate(`/${selectedEntity}/${selectedProduct?.productName}/tasks/withdrawals/bankaccount`, {
          state: { name: 'Withdrawals', tab: EWithdrawalTypeSchema.bankaccount },
        });
      } else {
        setValue(WithdrawalsViews.findIndex(route => route.path === lastEntryInUrl) ?? 0);
      }
    }
  }, [navigate, pathname, selectedEntity, selectedProduct]);

  return (
    <Stack spacing={2}>
      <Card
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: 'background.default',
        }}
      >
        <CardContent sx={{ padding: 0, ':last-child': { paddingBottom: 0 } }}>
          <Tabs
            value={value}
            onChange={(_event, newValue: number) => !isBlocking && setValue(newValue)}
            variant="scrollable"
            scrollButtons
            indicatorColor="primary"
            allowScrollButtonsMobile
          >
            {WithdrawalsViews.map(baseRoute => (
              <WithdrawalTab
                key={baseRoute.path}
                {...baseRoute}
                selectedEntity={selectedEntity}
                selectedProduct={selectedProduct}
                isBlocking={isBlocking}
              />
            ))}
          </Tabs>
        </CardContent>
      </Card>
      <WithdrawalsContext.Provider value={{ setIsBlocking, tableRef }}>
        <Outlet />
      </WithdrawalsContext.Provider>
    </Stack>
  );
};

export default memo(Withdrawals);
