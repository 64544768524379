import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faCreditCard, faDesktop, faDice, faGamepad, faKey } from '@fortawesome/free-solid-svg-icons';
import { BaseProductSchema, EProductTypeSchema } from '@greenisland-store/authorization';
import { Box, capitalize, Chip, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { useAuth } from '../../auth/AuthProvider';

enum ProductCategory {
  PROVIDER = 'ProSvc',
  PAYMENT = 'Payments',
  RED_PANDA = 'RedPanda',
}

function getIcon(productType?: EProductTypeSchema) {
  switch (productType) {
    case EProductTypeSchema.Authorization:
      return faKey;
    case EProductTypeSchema.Casino:
      return faDice;
    case EProductTypeSchema.OnlineCasino:
      return faDesktop;
    case EProductTypeSchema.GameServer:
      return faDice;
    case EProductTypeSchema.PaymentService:
      return faCreditCard;
    case EProductTypeSchema.ProviderService:
      return faGamepad;
    default:
      return faDesktop;
  }
}

const ProductSwitcher = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authData, setProduct } = useAuth();
  const { account } = authData || {};
  const { selectedProduct } = account || {};
  const entities = useMemo(() => authData?.startupAgent?.entities ?? [], [authData]);

  const onChange = useCallback(
    async (event: SelectChangeEvent<string>) => {
      const product = event.target.value;
      const selectedEntity = entities?.find(({ products }) =>
        products?.find(({ productName }) => productName === product)
      );
      const newSelectedProduct = selectedEntity?.products?.find(({ productName }) => productName === product);
      if (selectedEntity && newSelectedProduct && newSelectedProduct?.productName !== selectedProduct?.productName) {
        setProduct(selectedEntity.entityName, newSelectedProduct);
      }
    },
    [entities, selectedProduct, setProduct]
  );

  const tenants = useMemo(() => {
    const accumulator = new Map<string, Array<BaseProductSchema>>();

    entities
      ?.flatMap(entity => entity?.products)
      .forEach(product => {
        const tenantName = product?.productName
          ?.replace(ProductCategory.PROVIDER, '')
          ?.replace(ProductCategory.PAYMENT, '')
          ?.replace(ProductCategory.RED_PANDA, '');
        if (accumulator.has(tenantName)) accumulator.get(tenantName)?.push(product);
        else accumulator.set(tenantName, [product]);
      });

    const sortedTenants = Array.from(accumulator)?.sort(([, productsA], [, productsB]) => {
      return productsA[0]?.productName?.localeCompare(productsB[0]?.productName);
    });

    return sortedTenants?.map(([, products]) =>
      products?.sort((a, b) => {
        if (a?.productType === EProductTypeSchema.OnlineCasino && b?.productType !== EProductTypeSchema.OnlineCasino)
          return -1;
        if (a?.productType !== EProductTypeSchema.OnlineCasino && b?.productType === EProductTypeSchema.OnlineCasino)
          return 1;
        return a?.productName?.localeCompare(b?.productName);
      })
    );
  }, [entities]);

  const [tenant, product] = useMemo(() => {
    const updatedTenants = tenants.map(tenant => {
      return tenant.map(product => {
        if (product.productType === EProductTypeSchema.GameServer) {
          return { ...product, productType: ProductCategory.RED_PANDA };
        }
        return product;
      });
    });

    for (const tenant of updatedTenants) {
      for (const product of tenant) {
        if (product?.productName === selectedProduct?.productName) {
          return [tenant, product];
        }
      }
    }
    return [[]];
  }, [tenants, selectedProduct]);

  const sortedProductTypes = useMemo(
    () => tenant?.sort((a, b) => a?.productType?.localeCompare(b?.productType)),
    [tenant]
  );

  return (
    <Box display="flex" flexDirection="column" m={1} width={240}>
      <Select value={tenant[0]?.productName} onChange={onChange} size="small">
        {tenants?.map(([{ productId, productName, logoUrl }]) => (
          <MenuItem key={productId} value={productName}>
            <Box
              display="flex"
              alignItems="center"
              paddingY={0.5}
              sx={{
                '& img': {
                  width: theme.spacing(7),
                  height: theme.spacing(2.5),
                  marginRight: theme.spacing(1),
                  objectFit: 'contain',
                },
              }}
            >
              <img alt={productName} src={logoUrl} />
              <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{productName}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
      <Box display="flex" flexDirection="row" alignItems="center" gap={1} marginY={1}>
        <Typography>{capitalize(t('type'))}:</Typography>
        {sortedProductTypes?.length > 1 ? (
          <Select
            value={selectedProduct?.productName}
            onChange={onChange}
            sx={{ flexShrink: 1, width: 190 }}
            size="small"
          >
            {sortedProductTypes?.map(({ productId, productName, productType }) => (
              <MenuItem key={productId} value={productName}>
                <Box display="flex" alignItems="center" paddingY={0.5}>
                  <FontAwesomeIcon icon={getIcon(productType as EProductTypeSchema)} sx={{ width: 20, mr: 1 }} />
                  <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{productType}</Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Chip
            label={product?.productType}
            icon={<FontAwesomeIcon icon={getIcon(product?.productType as EProductTypeSchema)} />}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductSwitcher;
