import { createContext, useContext } from 'react';

export const ApiContext = createContext('https://boa.dev.greenisland.be');

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return context;
};
