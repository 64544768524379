import './amplifyStyles.css';
import { Navigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';

import AmplifyFooter from './AmplifyFooter';
import AmplifyHeader from './AmplifyHeader';
import AmplifySignInFooter from './AmplifySignInFooter';
import AmplifySignInHeader from './AmplifySignInHeader';

Amplify.configure(__AMPLIFY_CONFIG__);

const AmplifyApp = () => {
  return <Navigate to="/" />;
};

export default withAuthenticator(AmplifyApp, {
  components: {
    Header: AmplifyHeader,
    SignIn: {
      Header: AmplifySignInHeader,
      Footer: AmplifySignInFooter,
    },
    Footer: AmplifyFooter,
  },
  hideSignUp: true,
});
