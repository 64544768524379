import { useMemo } from 'react';
import { Route, Routes } from 'react-router';
import { EntitiesSchema } from '@greenisland-store/authorization';

import { IEntityState } from '../../auth/types';
import { BaseRoute, BaseRouting } from '../RouteInterfaces';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

const EmptyLayout = (props: Props) => {
  const routes = useMemo(
    () =>
      props.routes.map((route: BaseRoute) => {
        return {
          key: `${route.path}-${route.name}`,
          path: route.path || '',
          element: route.element,
          children: route.children,
          name: route.name,
        };
      }),
    [props.routes]
  );

  return (
    <Routes>
      {routes.map(route => {
        const Comp: any = route.element;
        return <Route key={route.key} path={route.path} element={<Comp />} />;
      })}
    </Routes>
  );
};

export default EmptyLayout;
