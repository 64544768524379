import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ELanguagesSchema,
  ServiceContractExtendedSchema,
  ServiceContractVersionSchema,
  useGetServiceContract,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { formatDateTimeFromUnix } from '@greenisland-common/helpers';

import ServiceContractHistoryContentDialog from './ServiceContractHistoryContentDialog';
import ServiceContractsUpdateDialog from './ServiceContractsUpdateDialog';

interface Props {
  serviceContractId: number;
  open: boolean;
  onClose: () => void;
}

const ServiceContractsViewDialog = ({ serviceContractId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const canReadServiceContract = usePermission(OnlineCasinoPermissions.getServiceContract);
  const canUpdateServiceContract = usePermission(OnlineCasinoPermissions.addVersionToServiceContract);
  const [selectedContent, setSelectedContent] = useState<ServiceContractVersionSchema | undefined>(undefined);
  const [selectedUpdateContent, setSelectedUpdateContent] = useState<ServiceContractExtendedSchema | undefined>(
    undefined
  );
  const [selectedNameLanguage, setSelectedNameLanguage] = useState<ELanguagesSchema | undefined>(undefined);

  const {
    data: serviceContract,
    isLoading,
    isError,
  } = useGetServiceContract(serviceContractId, {
    query: { enabled: canReadServiceContract },
  });

  const nameLanguages = useMemo<ELanguagesSchema[]>(() => {
    return (
      serviceContract?.name
        .map(name => name.language)
        .filter((language): language is ELanguagesSchema => language !== undefined) || []
    );
  }, [serviceContract]);

  useEffect(() => {
    if (selectedNameLanguage === undefined && nameLanguages.length > 0) {
      setSelectedNameLanguage(nameLanguages[0]);
    }
  }, [nameLanguages, selectedNameLanguage, serviceContract]);

  const columns = useMemo<GridColumns<ServiceContractVersionSchema>>(
    () => [
      {
        field: 'version',
        headerName: t('serviceContracts.history.table.header.version'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => `${row.version.majorVersion}.${row.version.minorVersion}.${row.version.patchVersion}`,
      },
      {
        field: 'requiredToSign',
        headerName: t('serviceContracts.history.table.header.requiredToSign'),
        sortable: false,
        flex: 1,
        renderCell: params => (params.value ? t('yes') : t('no')),
      },
      {
        field: 'created',
        headerName: t('serviceContracts.history.table.header.created'),
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatDateTimeFromUnix(row.created),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('serviceContracts.history.table.header.actions'),
        flex: 1,
        getActions: (params: GridRowParams<ServiceContractVersionSchema>) => [
          <>
            <Button
              key="contentButton"
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedContent(params.row);
              }}
            >
              {t('serviceContracts.history.table.viewContent')}
            </Button>
          </>,
        ],
      },
    ],
    [t]
  );

  const rows = useMemo(
    () => serviceContract?.versionHistory.map((contract, index) => ({ ...contract, id: index })) ?? [],
    [serviceContract]
  );

  return (
    <>
      <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
        <Box>
          <Card>
            <DialogTitle>{t('serviceContracts.viewContract.title')}</DialogTitle>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <DialogContent>
              <PermissionWrapper
                errorMessage={t('serviceContracts.errors.fetchServiceContract')}
                isError={isError}
                isLoading={isLoading}
                permission={OnlineCasinoPermissions.getServiceContract}
              >
                <>
                  <Box display="flex" gap={1} flexDirection="row" mb={2}>
                    {nameLanguages.length > 0 && selectedNameLanguage ? (
                      <Box display="flex" alignItems="center" gap={1} flexDirection="row">
                        <Select
                          id="nameLanguage"
                          label={t('serviceContracts.content.nameLanguage')}
                          value={selectedNameLanguage}
                          onChange={event => setSelectedNameLanguage(event.target.value as ELanguagesSchema)}
                          options={nameLanguages.map(language => language.toString())}
                          sx={{ minWidth: '180px' }}
                        />
                        {serviceContract?.name.find(name => name?.language === selectedNameLanguage)?.content}
                      </Box>
                    ) : null}
                    {canUpdateServiceContract && (
                      <Button
                        key="contentButton"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedUpdateContent(serviceContract);
                        }}
                        sx={{ alignSelf: 'start', marginLeft: 'auto' }}
                      >
                        {t('serviceContracts.content.updateContract')}
                      </Button>
                    )}
                  </Box>
                  <Box mb={1}>
                    {t('serviceContracts.viewContract.currentVersion')}:{' '}
                    {`${serviceContract?.latestVersion.version.majorVersion}.${serviceContract?.latestVersion.version.minorVersion}.${serviceContract?.latestVersion.version.patchVersion}`}
                  </Box>
                  <Box mb={2}>
                    {t('serviceContracts.viewContract.contractType')}: {serviceContract?.contractType}
                  </Box>
                  <DataGridContainer>
                    <Box>{t('serviceContracts.history.table.title')}</Box>
                    <LimitedDataGrid density="compact" autoHeight rows={rows} columns={columns} />
                  </DataGridContainer>
                </>
              </PermissionWrapper>
            </DialogContent>
          </Card>
        </Box>
      </Dialog>
      {selectedContent && (
        <ServiceContractHistoryContentDialog
          content={selectedContent.content}
          open={!!selectedContent}
          onClose={() => setSelectedContent(undefined)}
        />
      )}
      {selectedUpdateContent && (
        <ServiceContractsUpdateDialog
          required={selectedUpdateContent.latestVersion.requiredToSign}
          contractContent={selectedUpdateContent.latestVersion.content}
          contractId={selectedUpdateContent.serviceContractId}
          open={!!selectedUpdateContent}
          onClose={() => setSelectedUpdateContent(undefined)}
        />
      )}
    </>
  );
};

export default ServiceContractsViewDialog;
