import { useMemo } from 'react';
import { canAccess } from '@greenisland-core/permissions';
import { useGetCurrentAgent } from '@greenisland-store/authorization';
import type { Permission } from '@greenisland-types/authorization';

interface Options {
  strict: boolean;
}

const defaultOptions: Options = { strict: true };

export default function usePermission(permissions: Permission | Permission[], options: Options = defaultOptions) {
  const { data: currentAgent } = useGetCurrentAgent();

  return useMemo(
    () => (currentAgent ? canAccess(currentAgent, permissions, options.strict) : false),
    [currentAgent, permissions, options]
  );
}
