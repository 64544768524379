import { memo, useMemo, useState } from 'react';
import { matchPath, Outlet, useLocation } from 'react-router';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Fab, Typography, useScrollTrigger, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ErrorBoundary } from '@greenisland-common/components/atoms';

import { useAuth } from '../../auth/AuthProvider';
import { IEntityState } from '../../auth/types';
import { BaseRoute, BaseRouting } from '../RouteInterfaces';
import SideBar from './SideBar';
import TopAppBar from './TopAppBar';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  canAccessRoutes: Array<BaseRoute>;
}

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

function ScrollTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <StyledFab onClick={handleClick} color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </StyledFab>
    </Zoom>
  );
}

const BackofficeLayout = ({ routes, entitySelected, canAccessRoutes, identifier }: Props) => {
  const location = useLocation();
  const { logout } = useAuth();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const title = useMemo(() => {
    const findRouteName = (routes: Array<BaseRoute>, path: string): string => {
      for (const route of routes) {
        const match = matchPath(`${path}/${route.path}`, location.pathname);
        if (match) return route.name;
        if (route.children) {
          const routeName = findRouteName(route?.children ?? [], `${path}/${route?.path}`);
          if (routeName) return routeName;
        }
      }
      return '';
    };

    return findRouteName(routes, `${entitySelected.selectedEntity}/${entitySelected.selectedProduct.productName}`);
  }, [routes, entitySelected, location]);

  return (
    <Box display="flex">
      <SideBar
        entitySelected={entitySelected}
        routes={canAccessRoutes}
        handleDrawerToggle={() => setDrawerOpen(open => !open)}
        drawerOpen={drawerOpen}
        handleLogout={logout}
        identifier={identifier}
      />
      <Box flexGrow={1}>
        <TopAppBar
          handleDrawerToggle={() => setDrawerOpen(open => !open)}
          drawerOpen={drawerOpen}
          handleLogout={logout}
        />
        <Box py={10} px={2}>
          {title ? (
            <Typography variant="h4" sx={{ mb: 2 }}>
              {title}
            </Typography>
          ) : null}
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
      <ScrollTop />
    </Box>
  );
};

export default memo(BackofficeLayout);
