import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useQuickUserSearch } from '@greenisland-api';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../auth/AuthProvider';
import Searchfield from './Searchfield';

interface Props {
  closeDrawer?: () => void;
  className?: string;
}

const Searchbar = (props: Props) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { authData } = useAuth();
  const { account } = authData || {};
  const { selectedEntity, selectedProduct } = account || {};
  const { isLoading } = useQuickUserSearch(
    {
      searchValue,
    },
    {
      query: {
        enabled: !!searchValue && !!selectedEntity && !!selectedProduct,
        onSuccess(data) {
          if (data && data.length > 0) {
            enqueueSnackbar(t('common.resultFound', { count: data.length }), {
              variant: 'success',
            });

            if (data.length === 1) {
              navigate(`/${selectedEntity}/${selectedProduct?.productName}/users/${data[0].userId}/details`, {
                state: { name: 'User Details' },
              });
            }

            if (data.length > 1) {
              navigate(`/${selectedEntity}/${selectedProduct?.productName}/lookup/users?search=${searchValue}`, {
                state: { name: 'Lookup' },
              });
            }
          } else {
            enqueueSnackbar(t('noMatchForRequestedUser'), {
              variant: 'error',
            });
          }
          setSearchValue('');
        },
      },
    }
  );

  const handleSubmit = (params: string) => {
    if (props.closeDrawer !== undefined) {
      props.closeDrawer();
    }
    setSearchValue(params);
  };

  return (
    <div className={props.className}>
      <Searchfield handleSubmit={handleSubmit} fetching={isLoading} />
    </div>
  );
};

export default Searchbar;
