import { memo } from 'react';
import { EntitiesSchema } from '@greenisland-store/authorization';

import { IEntityState } from '../auth/types';
import { BaseRouting } from './RouteInterfaces';

interface Props extends BaseRouting {
  entitySelected?: IEntityState;
  agentEntities?: EntitiesSchema;
}

function LayoutContainer(props: Props) {
  const Layout = props.layout;

  return <Layout {...props} />;
}

export default memo(LayoutContainer);
