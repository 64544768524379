import { memo } from 'react';
import { StartupSettingsSchema, useGetStartupSettings } from '@greenisland-api';
import { EntitiesSchema } from '@greenisland-store/authorization';
import { Box, CircularProgress } from '@mui/material';

import { TenantProvider } from '../../app/context/TenantContext';
import { IEntityState } from '../../auth/types';
import { BaseRouting } from '../RouteInterfaces';
import BackofficeEntityWrapper from './BackofficeEntityWrapper';

interface BackofficeEntryProps extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

const BackofficeEntry = ({ identifier, ...restProps }: BackofficeEntryProps) => {
  const { data: startupSettings = {} as StartupSettingsSchema, isLoading } = useGetStartupSettings({
    query: { enabled: identifier === 'OnlineCasino' },
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <TenantProvider value={startupSettings}>
      <BackofficeEntityWrapper identifier={identifier} {...restProps} />
    </TenantProvider>
  );
};

export default memo(BackofficeEntry);
