import { AgentDetailsSchema, ProductSchema, StartupAgentSchema } from '@greenisland-store/authorization';

export enum EIdentityProvider {
  JumpCloud = 'jumpcloud',
  Cognito = 'cognito',
}

export interface AuthData {
  cognitoUsername?: string;
  startupAgent?: StartupAgentSchema;
  currentAgent?: AgentDetailsSchema;
  identityProvider?: EIdentityProvider;
  account?: {
    selectedEntity: string;
    selectedProduct: ProductSchema;
  };
}

export interface IEntityState {
  selectedProduct: ProductSchema;
  selectedEntity: string;
}
